<template>
	<el-menu router :default-active="activeUrl" :collapse="isCollapse"  :class="{leftMenuBox:!isCollapse,leftMenuBox64:isCollapse}" @open="handleOpen" @close="handleClose" background-color="#545c64"
	text-color="#fff" active-text-color="#409eff">
		
		<el-menu-item :index="item.url" :key="index" v-for="(item,index) in menuUrl">
			<i :class="item.icon"></i>
			<span slot="title">{{item.title}}</span>
		</el-menu-item>
	
	</el-menu>
</template>

<script>
	export default {
		data(){
			return{
				isCollapse:false,   // false 展开 ture 收起  
				menuUrl:[
					{icon:"el-icon-s-flag",title:"欢迎您",url:"/home/welcome"},
					{icon:"el-icon-s-custom",title:"我邀请的用户",url:"/home/InvitedUsers"},
					{icon:"el-icon-video-camera-solid",title:"我邀请的主播",url:"/home/invitedAnchor"},
					{icon:"el-icon-s-ticket",title:"收益汇总",url:"/home/rechargeTotal"},
					{icon:"el-icon-coin",title:"提现明细",url:"/home/withdrawal"},
					{icon:"el-icon-tickets",title:"收入流水",url:"/home/income"},
					{icon:"el-icon-notebook-2",title:"工会主播提现明细",url:"/home/myGuildUserPutList"},
					{icon:"el-icon-info",title:"账户信息",url:"/home/userCenter"},
					
				],
				activeUrl:null
			}
		},
		mounted() {
			this.eventBus.$on("changeIsCollapse",(isCollapse) => {
				this.isCollapse = isCollapse
			})
			
			let route = this.$route
			this.watchRoute(route)
		},
		watch: {
			$route(to) { // to, from
				// console.log(to,from)
				this.watchRoute(to)
			},
		},
		methods: {
			watchRoute(route){
				this.activeUrl = route.path
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		}
	}
</script>

<style>
	.el-menu{
		border:none !important;
		position: fixed !important;
		width: 100%;
		height: 100%;
		left: 0;
		top:0;
	}
</style>
